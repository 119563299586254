export default {
  catalog: 'Каталог',
  assortment: 'Ассортимент',
  transactions: 'Транзакции',
  operationsHistory: 'История операций',
  operationsProducts: 'Операции',
  transfers: 'Переводы',
  exchange: 'Биржа',
  auction: 'Аукцион',
  information: 'Информация',
  verification: 'Верификация',
  replenishments: 'Пополнения',
  topUpBalance: 'Пополнить баланс',
  betweenYourAccounts: 'Между своими счетами',
  nameless: 'Безымянный',
  profile: 'Профиль',
  historyOfOrders: 'История заказов',
  logout: 'Выйти',
  payments: 'Покупки',
  acceptance: 'Приемка',
  writeoff: 'Списание',
}
