import { default as indexoWGwhqHXP3Meta } from "/home/ifc_web/pages/assortment/index.vue?macro=true";
import { default as indexHtmjpvNcnrMeta } from "/home/ifc_web/pages/cart/index.vue?macro=true";
import { default as indexnxLYjg8pkKMeta } from "/home/ifc_web/pages/catalog/index.vue?macro=true";
import { default as indexPwc4BRBySVMeta } from "/home/ifc_web/pages/catalogg/index.vue?macro=true";
import { default as indexspF1xFAgJ9Meta } from "/home/ifc_web/pages/confirm/index.vue?macro=true";
import { default as indexLv9859aZIPMeta } from "/home/ifc_web/pages/history/[_id]/index.vue?macro=true";
import { default as indexUtBkgsAFX2Meta } from "/home/ifc_web/pages/history/index.vue?macro=true";
import { default as index3wxfVf6t7uMeta } from "/home/ifc_web/pages/index.vue?macro=true";
import { default as indexmDk2wwOZcsMeta } from "/home/ifc_web/pages/operation/index.vue?macro=true";
import { default as indexnVbXJrZZbIMeta } from "/home/ifc_web/pages/operations-history/index.vue?macro=true";
import { default as indexmqc02nk6ITMeta } from "/home/ifc_web/pages/operationss/index.vue?macro=true";
import { default as indexcf8qAkZ0LjMeta } from "/home/ifc_web/pages/order/index.vue?macro=true";
import { default as indexf10gI8Nt91Meta } from "/home/ifc_web/pages/order/myself/index.vue?macro=true";
import { default as indexNPoV5P8X76Meta } from "/home/ifc_web/pages/profile/index.vue?macro=true";
import { default as indexiYpAGNnEkZMeta } from "/home/ifc_web/pages/profile/verification/index.vue?macro=true";
import { default as indexobOIHWt5HrMeta } from "/home/ifc_web/pages/recovery/[email]/index.vue?macro=true";
import { default as indexMMLzkxjYMTMeta } from "/home/ifc_web/pages/recovery/confirmed/index.vue?macro=true";
import { default as indexycC6JfhUUVMeta } from "/home/ifc_web/pages/recovery/expired/index.vue?macro=true";
import { default as indexmNX3jTu7zLMeta } from "/home/ifc_web/pages/recovery/index.vue?macro=true";
import { default as indexOno9mtIhTyMeta } from "/home/ifc_web/pages/recovery/verify/index.vue?macro=true";
import { default as indexOpzn4FJSCgMeta } from "/home/ifc_web/pages/signin/index.vue?macro=true";
import { default as indexyB9YbmLqEzMeta } from "/home/ifc_web/pages/signup/[email]/index.vue?macro=true";
import { default as indextRL7yPOnhOMeta } from "/home/ifc_web/pages/signup/expired/index.vue?macro=true";
import { default as indexrb3TvYeixQMeta } from "/home/ifc_web/pages/signup/index.vue?macro=true";
import { default as indexMzzpK5KCnBMeta } from "/home/ifc_web/pages/signup/verify/index.vue?macro=true";
import { default as indexqlEOk0b9cjMeta } from "/home/ifc_web/pages/transaction/index.vue?macro=true";
import { default as index0caarbid39Meta } from "/home/ifc_web/pages/transaction/payments/index.vue?macro=true";
import { default as indexGkwUfmtOhHMeta } from "/home/ifc_web/pages/transaction/replenishment/index.vue?macro=true";
import { default as indexMIBvpWFKAlMeta } from "/home/ifc_web/pages/transaction/transfer/index.vue?macro=true";
import { default as transactionmVWVbwQjUjMeta } from "/home/ifc_web/pages/transaction.vue?macro=true";
export default [
  {
    name: indexoWGwhqHXP3Meta?.name ?? "assortment___en",
    path: indexoWGwhqHXP3Meta?.path ?? "/en/assortment",
    meta: indexoWGwhqHXP3Meta || {},
    alias: indexoWGwhqHXP3Meta?.alias || [],
    redirect: indexoWGwhqHXP3Meta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/assortment/index.vue").then(m => m.default || m)
  },
  {
    name: indexoWGwhqHXP3Meta?.name ?? "assortment___ru",
    path: indexoWGwhqHXP3Meta?.path ?? "/assortment",
    meta: indexoWGwhqHXP3Meta || {},
    alias: indexoWGwhqHXP3Meta?.alias || [],
    redirect: indexoWGwhqHXP3Meta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/assortment/index.vue").then(m => m.default || m)
  },
  {
    name: indexHtmjpvNcnrMeta?.name ?? "cart___en",
    path: indexHtmjpvNcnrMeta?.path ?? "/en/cart",
    meta: indexHtmjpvNcnrMeta || {},
    alias: indexHtmjpvNcnrMeta?.alias || [],
    redirect: indexHtmjpvNcnrMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexHtmjpvNcnrMeta?.name ?? "cart___ru",
    path: indexHtmjpvNcnrMeta?.path ?? "/cart",
    meta: indexHtmjpvNcnrMeta || {},
    alias: indexHtmjpvNcnrMeta?.alias || [],
    redirect: indexHtmjpvNcnrMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexnxLYjg8pkKMeta?.name ?? "catalog___en",
    path: indexnxLYjg8pkKMeta?.path ?? "/en/catalog",
    meta: indexnxLYjg8pkKMeta || {},
    alias: indexnxLYjg8pkKMeta?.alias || [],
    redirect: indexnxLYjg8pkKMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexnxLYjg8pkKMeta?.name ?? "catalog___ru",
    path: indexnxLYjg8pkKMeta?.path ?? "/catalog",
    meta: indexnxLYjg8pkKMeta || {},
    alias: indexnxLYjg8pkKMeta?.alias || [],
    redirect: indexnxLYjg8pkKMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexPwc4BRBySVMeta?.name ?? "catalogg___en",
    path: indexPwc4BRBySVMeta?.path ?? "/en/catalogg",
    meta: indexPwc4BRBySVMeta || {},
    alias: indexPwc4BRBySVMeta?.alias || [],
    redirect: indexPwc4BRBySVMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/catalogg/index.vue").then(m => m.default || m)
  },
  {
    name: indexPwc4BRBySVMeta?.name ?? "catalogg___ru",
    path: indexPwc4BRBySVMeta?.path ?? "/catalogg",
    meta: indexPwc4BRBySVMeta || {},
    alias: indexPwc4BRBySVMeta?.alias || [],
    redirect: indexPwc4BRBySVMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/catalogg/index.vue").then(m => m.default || m)
  },
  {
    name: indexspF1xFAgJ9Meta?.name ?? "confirm___en",
    path: indexspF1xFAgJ9Meta?.path ?? "/en/confirm",
    meta: indexspF1xFAgJ9Meta || {},
    alias: indexspF1xFAgJ9Meta?.alias || [],
    redirect: indexspF1xFAgJ9Meta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: indexspF1xFAgJ9Meta?.name ?? "confirm___ru",
    path: indexspF1xFAgJ9Meta?.path ?? "/confirm",
    meta: indexspF1xFAgJ9Meta || {},
    alias: indexspF1xFAgJ9Meta?.alias || [],
    redirect: indexspF1xFAgJ9Meta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: indexLv9859aZIPMeta?.name ?? "history-_id___en",
    path: indexLv9859aZIPMeta?.path ?? "/en/history/:_id()",
    meta: indexLv9859aZIPMeta || {},
    alias: indexLv9859aZIPMeta?.alias || [],
    redirect: indexLv9859aZIPMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/history/[_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexLv9859aZIPMeta?.name ?? "history-_id___ru",
    path: indexLv9859aZIPMeta?.path ?? "/history/:_id()",
    meta: indexLv9859aZIPMeta || {},
    alias: indexLv9859aZIPMeta?.alias || [],
    redirect: indexLv9859aZIPMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/history/[_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexUtBkgsAFX2Meta?.name ?? "history___en",
    path: indexUtBkgsAFX2Meta?.path ?? "/en/history",
    meta: indexUtBkgsAFX2Meta || {},
    alias: indexUtBkgsAFX2Meta?.alias || [],
    redirect: indexUtBkgsAFX2Meta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/history/index.vue").then(m => m.default || m)
  },
  {
    name: indexUtBkgsAFX2Meta?.name ?? "history___ru",
    path: indexUtBkgsAFX2Meta?.path ?? "/history",
    meta: indexUtBkgsAFX2Meta || {},
    alias: indexUtBkgsAFX2Meta?.alias || [],
    redirect: indexUtBkgsAFX2Meta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/history/index.vue").then(m => m.default || m)
  },
  {
    name: index3wxfVf6t7uMeta?.name ?? "index___en",
    path: index3wxfVf6t7uMeta?.path ?? "/en",
    meta: index3wxfVf6t7uMeta || {},
    alias: index3wxfVf6t7uMeta?.alias || [],
    redirect: index3wxfVf6t7uMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index3wxfVf6t7uMeta?.name ?? "index___ru",
    path: index3wxfVf6t7uMeta?.path ?? "/",
    meta: index3wxfVf6t7uMeta || {},
    alias: index3wxfVf6t7uMeta?.alias || [],
    redirect: index3wxfVf6t7uMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexmDk2wwOZcsMeta?.name ?? "operation___en",
    path: indexmDk2wwOZcsMeta?.path ?? "/en/operation",
    meta: indexmDk2wwOZcsMeta || {},
    alias: indexmDk2wwOZcsMeta?.alias || [],
    redirect: indexmDk2wwOZcsMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/operation/index.vue").then(m => m.default || m)
  },
  {
    name: indexmDk2wwOZcsMeta?.name ?? "operation___ru",
    path: indexmDk2wwOZcsMeta?.path ?? "/operation",
    meta: indexmDk2wwOZcsMeta || {},
    alias: indexmDk2wwOZcsMeta?.alias || [],
    redirect: indexmDk2wwOZcsMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/operation/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVbXJrZZbIMeta?.name ?? "operations-history___en",
    path: indexnVbXJrZZbIMeta?.path ?? "/en/operations-history",
    meta: indexnVbXJrZZbIMeta || {},
    alias: indexnVbXJrZZbIMeta?.alias || [],
    redirect: indexnVbXJrZZbIMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/operations-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexnVbXJrZZbIMeta?.name ?? "operations-history___ru",
    path: indexnVbXJrZZbIMeta?.path ?? "/operations-history",
    meta: indexnVbXJrZZbIMeta || {},
    alias: indexnVbXJrZZbIMeta?.alias || [],
    redirect: indexnVbXJrZZbIMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/operations-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexmqc02nk6ITMeta?.name ?? "operationss___en",
    path: indexmqc02nk6ITMeta?.path ?? "/en/operationss",
    meta: indexmqc02nk6ITMeta || {},
    alias: indexmqc02nk6ITMeta?.alias || [],
    redirect: indexmqc02nk6ITMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/operationss/index.vue").then(m => m.default || m)
  },
  {
    name: indexmqc02nk6ITMeta?.name ?? "operationss___ru",
    path: indexmqc02nk6ITMeta?.path ?? "/operationss",
    meta: indexmqc02nk6ITMeta || {},
    alias: indexmqc02nk6ITMeta?.alias || [],
    redirect: indexmqc02nk6ITMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/operationss/index.vue").then(m => m.default || m)
  },
  {
    name: indexcf8qAkZ0LjMeta?.name ?? "order___en",
    path: indexcf8qAkZ0LjMeta?.path ?? "/en/order",
    meta: indexcf8qAkZ0LjMeta || {},
    alias: indexcf8qAkZ0LjMeta?.alias || [],
    redirect: indexcf8qAkZ0LjMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexcf8qAkZ0LjMeta?.name ?? "order___ru",
    path: indexcf8qAkZ0LjMeta?.path ?? "/order",
    meta: indexcf8qAkZ0LjMeta || {},
    alias: indexcf8qAkZ0LjMeta?.alias || [],
    redirect: indexcf8qAkZ0LjMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/order/index.vue").then(m => m.default || m)
  },
  {
    name: indexf10gI8Nt91Meta?.name ?? "order-myself___en",
    path: indexf10gI8Nt91Meta?.path ?? "/en/order/myself",
    meta: indexf10gI8Nt91Meta || {},
    alias: indexf10gI8Nt91Meta?.alias || [],
    redirect: indexf10gI8Nt91Meta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/order/myself/index.vue").then(m => m.default || m)
  },
  {
    name: indexf10gI8Nt91Meta?.name ?? "order-myself___ru",
    path: indexf10gI8Nt91Meta?.path ?? "/order/myself",
    meta: indexf10gI8Nt91Meta || {},
    alias: indexf10gI8Nt91Meta?.alias || [],
    redirect: indexf10gI8Nt91Meta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/order/myself/index.vue").then(m => m.default || m)
  },
  {
    name: indexNPoV5P8X76Meta?.name ?? "profile___en",
    path: indexNPoV5P8X76Meta?.path ?? "/en/profile",
    meta: indexNPoV5P8X76Meta || {},
    alias: indexNPoV5P8X76Meta?.alias || [],
    redirect: indexNPoV5P8X76Meta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexNPoV5P8X76Meta?.name ?? "profile___ru",
    path: indexNPoV5P8X76Meta?.path ?? "/profile",
    meta: indexNPoV5P8X76Meta || {},
    alias: indexNPoV5P8X76Meta?.alias || [],
    redirect: indexNPoV5P8X76Meta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexiYpAGNnEkZMeta?.name ?? "profile-verification___en",
    path: indexiYpAGNnEkZMeta?.path ?? "/en/profile/verification",
    meta: indexiYpAGNnEkZMeta || {},
    alias: indexiYpAGNnEkZMeta?.alias || [],
    redirect: indexiYpAGNnEkZMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/profile/verification/index.vue").then(m => m.default || m)
  },
  {
    name: indexiYpAGNnEkZMeta?.name ?? "profile-verification___ru",
    path: indexiYpAGNnEkZMeta?.path ?? "/profile/verification",
    meta: indexiYpAGNnEkZMeta || {},
    alias: indexiYpAGNnEkZMeta?.alias || [],
    redirect: indexiYpAGNnEkZMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/profile/verification/index.vue").then(m => m.default || m)
  },
  {
    name: indexobOIHWt5HrMeta?.name ?? "recovery-email___en",
    path: indexobOIHWt5HrMeta?.path ?? "/en/recovery/:email()",
    meta: indexobOIHWt5HrMeta || {},
    alias: indexobOIHWt5HrMeta?.alias || [],
    redirect: indexobOIHWt5HrMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/recovery/[email]/index.vue").then(m => m.default || m)
  },
  {
    name: indexobOIHWt5HrMeta?.name ?? "recovery-email___ru",
    path: indexobOIHWt5HrMeta?.path ?? "/recovery/:email()",
    meta: indexobOIHWt5HrMeta || {},
    alias: indexobOIHWt5HrMeta?.alias || [],
    redirect: indexobOIHWt5HrMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/recovery/[email]/index.vue").then(m => m.default || m)
  },
  {
    name: indexMMLzkxjYMTMeta?.name ?? "recovery-confirmed___en",
    path: indexMMLzkxjYMTMeta?.path ?? "/en/recovery/confirmed",
    meta: indexMMLzkxjYMTMeta || {},
    alias: indexMMLzkxjYMTMeta?.alias || [],
    redirect: indexMMLzkxjYMTMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/recovery/confirmed/index.vue").then(m => m.default || m)
  },
  {
    name: indexMMLzkxjYMTMeta?.name ?? "recovery-confirmed___ru",
    path: indexMMLzkxjYMTMeta?.path ?? "/recovery/confirmed",
    meta: indexMMLzkxjYMTMeta || {},
    alias: indexMMLzkxjYMTMeta?.alias || [],
    redirect: indexMMLzkxjYMTMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/recovery/confirmed/index.vue").then(m => m.default || m)
  },
  {
    name: indexycC6JfhUUVMeta?.name ?? "recovery-expired___en",
    path: indexycC6JfhUUVMeta?.path ?? "/en/recovery/expired",
    meta: indexycC6JfhUUVMeta || {},
    alias: indexycC6JfhUUVMeta?.alias || [],
    redirect: indexycC6JfhUUVMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/recovery/expired/index.vue").then(m => m.default || m)
  },
  {
    name: indexycC6JfhUUVMeta?.name ?? "recovery-expired___ru",
    path: indexycC6JfhUUVMeta?.path ?? "/recovery/expired",
    meta: indexycC6JfhUUVMeta || {},
    alias: indexycC6JfhUUVMeta?.alias || [],
    redirect: indexycC6JfhUUVMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/recovery/expired/index.vue").then(m => m.default || m)
  },
  {
    name: indexmNX3jTu7zLMeta?.name ?? "recovery___en",
    path: indexmNX3jTu7zLMeta?.path ?? "/en/recovery",
    meta: indexmNX3jTu7zLMeta || {},
    alias: indexmNX3jTu7zLMeta?.alias || [],
    redirect: indexmNX3jTu7zLMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/recovery/index.vue").then(m => m.default || m)
  },
  {
    name: indexmNX3jTu7zLMeta?.name ?? "recovery___ru",
    path: indexmNX3jTu7zLMeta?.path ?? "/recovery",
    meta: indexmNX3jTu7zLMeta || {},
    alias: indexmNX3jTu7zLMeta?.alias || [],
    redirect: indexmNX3jTu7zLMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/recovery/index.vue").then(m => m.default || m)
  },
  {
    name: indexOno9mtIhTyMeta?.name ?? "recovery-verify___en",
    path: indexOno9mtIhTyMeta?.path ?? "/en/recovery/verify",
    meta: indexOno9mtIhTyMeta || {},
    alias: indexOno9mtIhTyMeta?.alias || [],
    redirect: indexOno9mtIhTyMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/recovery/verify/index.vue").then(m => m.default || m)
  },
  {
    name: indexOno9mtIhTyMeta?.name ?? "recovery-verify___ru",
    path: indexOno9mtIhTyMeta?.path ?? "/recovery/verify",
    meta: indexOno9mtIhTyMeta || {},
    alias: indexOno9mtIhTyMeta?.alias || [],
    redirect: indexOno9mtIhTyMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/recovery/verify/index.vue").then(m => m.default || m)
  },
  {
    name: indexOpzn4FJSCgMeta?.name ?? "signin___en",
    path: indexOpzn4FJSCgMeta?.path ?? "/en/signin",
    meta: indexOpzn4FJSCgMeta || {},
    alias: indexOpzn4FJSCgMeta?.alias || [],
    redirect: indexOpzn4FJSCgMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/signin/index.vue").then(m => m.default || m)
  },
  {
    name: indexOpzn4FJSCgMeta?.name ?? "signin___ru",
    path: indexOpzn4FJSCgMeta?.path ?? "/signin",
    meta: indexOpzn4FJSCgMeta || {},
    alias: indexOpzn4FJSCgMeta?.alias || [],
    redirect: indexOpzn4FJSCgMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/signin/index.vue").then(m => m.default || m)
  },
  {
    name: indexyB9YbmLqEzMeta?.name ?? "signup-email___en",
    path: indexyB9YbmLqEzMeta?.path ?? "/en/signup/:email()",
    meta: indexyB9YbmLqEzMeta || {},
    alias: indexyB9YbmLqEzMeta?.alias || [],
    redirect: indexyB9YbmLqEzMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/signup/[email]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyB9YbmLqEzMeta?.name ?? "signup-email___ru",
    path: indexyB9YbmLqEzMeta?.path ?? "/signup/:email()",
    meta: indexyB9YbmLqEzMeta || {},
    alias: indexyB9YbmLqEzMeta?.alias || [],
    redirect: indexyB9YbmLqEzMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/signup/[email]/index.vue").then(m => m.default || m)
  },
  {
    name: indextRL7yPOnhOMeta?.name ?? "signup-expired___en",
    path: indextRL7yPOnhOMeta?.path ?? "/en/signup/expired",
    meta: indextRL7yPOnhOMeta || {},
    alias: indextRL7yPOnhOMeta?.alias || [],
    redirect: indextRL7yPOnhOMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/signup/expired/index.vue").then(m => m.default || m)
  },
  {
    name: indextRL7yPOnhOMeta?.name ?? "signup-expired___ru",
    path: indextRL7yPOnhOMeta?.path ?? "/signup/expired",
    meta: indextRL7yPOnhOMeta || {},
    alias: indextRL7yPOnhOMeta?.alias || [],
    redirect: indextRL7yPOnhOMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/signup/expired/index.vue").then(m => m.default || m)
  },
  {
    name: indexrb3TvYeixQMeta?.name ?? "signup___en",
    path: indexrb3TvYeixQMeta?.path ?? "/en/signup",
    meta: indexrb3TvYeixQMeta || {},
    alias: indexrb3TvYeixQMeta?.alias || [],
    redirect: indexrb3TvYeixQMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexrb3TvYeixQMeta?.name ?? "signup___ru",
    path: indexrb3TvYeixQMeta?.path ?? "/signup",
    meta: indexrb3TvYeixQMeta || {},
    alias: indexrb3TvYeixQMeta?.alias || [],
    redirect: indexrb3TvYeixQMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/signup/index.vue").then(m => m.default || m)
  },
  {
    name: indexMzzpK5KCnBMeta?.name ?? "signup-verify___en",
    path: indexMzzpK5KCnBMeta?.path ?? "/en/signup/verify",
    meta: indexMzzpK5KCnBMeta || {},
    alias: indexMzzpK5KCnBMeta?.alias || [],
    redirect: indexMzzpK5KCnBMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/signup/verify/index.vue").then(m => m.default || m)
  },
  {
    name: indexMzzpK5KCnBMeta?.name ?? "signup-verify___ru",
    path: indexMzzpK5KCnBMeta?.path ?? "/signup/verify",
    meta: indexMzzpK5KCnBMeta || {},
    alias: indexMzzpK5KCnBMeta?.alias || [],
    redirect: indexMzzpK5KCnBMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/signup/verify/index.vue").then(m => m.default || m)
  },
  {
    path: transactionmVWVbwQjUjMeta?.path ?? "/en/transaction",
    children: [
  {
    name: indexqlEOk0b9cjMeta?.name ?? "transaction___en",
    path: indexqlEOk0b9cjMeta?.path ?? "",
    meta: indexqlEOk0b9cjMeta || {},
    alias: indexqlEOk0b9cjMeta?.alias || [],
    redirect: indexqlEOk0b9cjMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/transaction/index.vue").then(m => m.default || m)
  },
  {
    name: index0caarbid39Meta?.name ?? "transaction-payments___en",
    path: index0caarbid39Meta?.path ?? "payments",
    meta: index0caarbid39Meta || {},
    alias: index0caarbid39Meta?.alias || [],
    redirect: index0caarbid39Meta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/transaction/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexGkwUfmtOhHMeta?.name ?? "transaction-replenishment___en",
    path: indexGkwUfmtOhHMeta?.path ?? "replenishment",
    meta: indexGkwUfmtOhHMeta || {},
    alias: indexGkwUfmtOhHMeta?.alias || [],
    redirect: indexGkwUfmtOhHMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/transaction/replenishment/index.vue").then(m => m.default || m)
  },
  {
    name: indexMIBvpWFKAlMeta?.name ?? "transaction-transfer___en",
    path: indexMIBvpWFKAlMeta?.path ?? "transfer",
    meta: indexMIBvpWFKAlMeta || {},
    alias: indexMIBvpWFKAlMeta?.alias || [],
    redirect: indexMIBvpWFKAlMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/transaction/transfer/index.vue").then(m => m.default || m)
  }
],
    name: transactionmVWVbwQjUjMeta?.name ?? undefined,
    meta: transactionmVWVbwQjUjMeta || {},
    alias: transactionmVWVbwQjUjMeta?.alias || [],
    redirect: transactionmVWVbwQjUjMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/transaction.vue").then(m => m.default || m)
  },
  {
    path: transactionmVWVbwQjUjMeta?.path ?? "/transaction",
    children: [
  {
    name: indexqlEOk0b9cjMeta?.name ?? "transaction___ru",
    path: indexqlEOk0b9cjMeta?.path ?? "",
    meta: indexqlEOk0b9cjMeta || {},
    alias: indexqlEOk0b9cjMeta?.alias || [],
    redirect: indexqlEOk0b9cjMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/transaction/index.vue").then(m => m.default || m)
  },
  {
    name: index0caarbid39Meta?.name ?? "transaction-payments___ru",
    path: index0caarbid39Meta?.path ?? "payments",
    meta: index0caarbid39Meta || {},
    alias: index0caarbid39Meta?.alias || [],
    redirect: index0caarbid39Meta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/transaction/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexGkwUfmtOhHMeta?.name ?? "transaction-replenishment___ru",
    path: indexGkwUfmtOhHMeta?.path ?? "replenishment",
    meta: indexGkwUfmtOhHMeta || {},
    alias: indexGkwUfmtOhHMeta?.alias || [],
    redirect: indexGkwUfmtOhHMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/transaction/replenishment/index.vue").then(m => m.default || m)
  },
  {
    name: indexMIBvpWFKAlMeta?.name ?? "transaction-transfer___ru",
    path: indexMIBvpWFKAlMeta?.path ?? "transfer",
    meta: indexMIBvpWFKAlMeta || {},
    alias: indexMIBvpWFKAlMeta?.alias || [],
    redirect: indexMIBvpWFKAlMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/transaction/transfer/index.vue").then(m => m.default || m)
  }
],
    name: transactionmVWVbwQjUjMeta?.name ?? undefined,
    meta: transactionmVWVbwQjUjMeta || {},
    alias: transactionmVWVbwQjUjMeta?.alias || [],
    redirect: transactionmVWVbwQjUjMeta?.redirect || undefined,
    component: () => import("/home/ifc_web/pages/transaction.vue").then(m => m.default || m)
  }
]