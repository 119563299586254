import containerEn from './container/en';

export default {
  filters: 'Filters',
  resetFilters: 'Reset filters',
  addFilters: 'Add filters',
  apply: 'Apply',
  allTime: 'During all this time',
  date: 'Date',
  cancel: 'Cancel',
  save: 'Save',
  container: containerEn,
}
